import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EditorContent, useEditor } from '@tiptap/react';
import { styled } from '@mui/material/styles';

import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Image from '@tiptap/extension-image';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import TaskList from '@tiptap/extension-task-list';
import Mention from 'ui/Mention/mantyMention';
import { BUDGET, DECISION, ModuleT } from 'business/constants';

import { MentionStyle } from 'ui/Mention/styles';
import { Suggestion } from 'ui/Mention/Suggestion';
import { UserInfoWithIdT } from 'business/definitions';
import MenuBar from './MenuBar';

import './textEditor.scss';
import { EnrichedTextStyleExtension } from './EnrichedTextStyleExtension';
import { EnrichedTaskItemStyleExtension } from './EnrichedTaskItemStyleExtension';
import Popper from '@mui/material/Popper';
import { SHADOW } from 'styles/theme';
import EnrichedLinkExtension from './EnrichedLinkExtension';
import { useGetSuggestedUsers } from 'lib/alert/hooks/useFetchSuggestedusers';

const StyEditorContent = styled(EditorContent, {
    name: 'StyEditorContent',
})(({ theme }) => ({
    '& .ProseMirror': {
        padding: '0 0.5rem',
    },
    ...MentionStyle(theme),
}));

const StyPopper = styled(Popper, {
    name: 'StyPopper',
    shouldForwardProp: (prop) => prop !== 'width',
})<{
    width: number;
}>(({ theme, width }) => ({
    width,
    zIndex: '2 !important',
    boxShadow: SHADOW,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
}));

type Props = {
    isEditable: boolean;
    content: string;
    canEdit: boolean;
    parentRef?: React.RefObject<HTMLDivElement> | null;
    style?: React.CSSProperties;
    onSave: (html: string, users?: UserInfoWithIdT[]) => void;
    mentionEnabledFor?: typeof DECISION | typeof BUDGET;
    module: ModuleT;
    isParagraphWidget?: boolean;
    mentionBoundingBox?: { left: number; right: number };
    trackerFunctions?: Partial<{
        trackMentionCharClicked?: () => void;
        trackMentionCreated?: () => void;
    }>;
};

export default function TextEditor({
    isEditable,
    content,
    canEdit,
    onSave,
    style = {},
    parentRef = undefined,
    mentionEnabledFor = undefined,
    module,
    isParagraphWidget = false,
    mentionBoundingBox = undefined,
    trackerFunctions = undefined,
}: Props): React.ReactElement {
    const { t } = useTranslation();
    const isMentionEnabled = mentionEnabledFor !== undefined;
    const ref = React.useRef<HTMLDivElement>(null);
    const { data: users, isInitialLoading: isLoading } = useGetSuggestedUsers(
        module,
        isMentionEnabled,
    );

    const mentionExtension = isMentionEnabled
        ? [
              Mention.configure({
                  boundingBox: mentionBoundingBox ?? { left: 56, right: 56 },
                  margin: 16,
                  isParagraphWidget,
                  suggestion: Suggestion(
                      users,
                      trackerFunctions?.trackMentionCreated,
                  ),
              }),
          ]
        : [];

    const onSaveAction = users ? (html: string) => onSave(html, users) : onSave;

    const editor = useEditor(
        {
            editable: isEditable,
            extensions: [
                StarterKit.configure({
                    heading: {
                        levels: [1, 2],
                    },
                }),
                Highlight.configure({ multicolor: true }),
                Color,
                EnrichedTextStyleExtension,
                Underline,
                Image.configure({ allowBase64: true }),
                EnrichedLinkExtension,
                Placeholder.configure({
                    placeholder: (tiptapProps) => {
                        if (!canEdit) {
                            return '';
                        }
                        if (tiptapProps.editor.options.editable)
                            return mentionEnabledFor
                                ? t(
                                      'dashboardHeader.editingEmptyParagraphWithMention',
                                  )
                                : t('dashboardHeader.editingEmptyParagraph');
                        return t('dashboardHeader.emptyParagraph');
                    },
                    showOnlyWhenEditable: false,
                }),
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                Table.configure({
                    resizable: true,
                }),
                TableRow,
                TableHeader,
                TableCell,
                TaskList,
                EnrichedTaskItemStyleExtension.configure({
                    // NB: "onReadOnlyChecked: () => canEdit" would be a better UX, because
                    // it would allow the (un)checking of boxes without going into edit mode,
                    // but as of version 2.0beta209, the 'update' event is not triggered when
                    // a checkbox is edit and isEditable is false
                    // see https://github.com/ueberdosis/tiptap/issues/3676
                    onReadOnlyChecked: () => false,
                    nested: true,
                }),
                ...mentionExtension,
            ],
            content,
        },
        [isLoading],
    );

    React.useEffect(() => {
        if (editor) {
            editor.setEditable(isEditable);
            if (isEditable) {
                editor.commands.focus();
            }
        }
    }, [isEditable, editor]);

    const menuBar = (
        <MenuBar
            editor={editor}
            onSave={onSaveAction}
            mentionEnabled={isMentionEnabled}
            trackMentionCharClicked={trackerFunctions?.trackMentionCharClicked}
        />
    );

    return (
        <div ref={ref} style={style}>
            {isEditable && isParagraphWidget && (
                <StyPopper
                    width={parentRef?.current?.clientWidth ?? 0}
                    open={isEditable}
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'preventOverflow',
                                options: {
                                    padding: 16,
                                },
                            },
                        ],
                    }}
                    anchorEl={ref.current}
                    placement="top-start"
                >
                    {menuBar}
                </StyPopper>
            )}
            {isEditable && !isParagraphWidget && menuBar}
            <StyEditorContent editor={editor} />
        </div>
    );
}
